import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
Vue.config.productionTip = false;
const routes = [
  {
    path: "/",
    component: () => import("@/views/index.vue"),
  },
  {
    path: "/tiktok",
    component: () => import("@/views/tiktok/index.vue"),
  },
  {
    path: "/shopee",
    component: () => import("@/views/shopee/index.vue"),
  },
  { path: "/:code", component: () => import("@/views/index.vue") },
];

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = new VueRouter({
  mode: "history",
  base: "/",
  routes, // short for `routes: routes`
});
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
